<script setup lang="ts">
import type {ApiJournalSimpleProps} from "~/projectCommon/frontend/model/ApiJournalProps";
import {useMyRoutePaths} from "~/mylib/MyRoutePaths";

const props = defineProps<{
  journals: ApiJournalSimpleProps[]
}>()

const routePaths = useMyRoutePaths()
</script>

<template>
  <div class="container">
    <ul class="journal-list row">
      <li v-for="journal in props.journals" :key="journal.id" class="col-12 col-md-4 journal-list-item">
        <div class="journal-relative-div">
          <div class="image-div">
            <a v-if="journal.imageFile" :href="routePaths.journal(journal.id)">
              <img :src="journal.imageFile.displayImageFile.url"
                   :alt="journal.imageFile.displayImageFile.url"
                   class="img-fluid object-fit-contain"
              >
            </a>
          </div>
          <div class="text-div">
            <h3 class="post-title"><a :href="routePaths.journal(journal.id)">{{ journal.title }}</a></h3>
            <p class="post-metadata">
              <i class="fa-light fa-clock me-1"></i>
              <time>{{ journal.displayableStartAt }}</time>
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.journal-list {
  padding: 0;
  list-style: none;
}

.journal-list-item {
  .journal-relative-div {
    aspect-ratio: 4/3;
    margin-bottom: 1rem;
  }

  .image-div {
    width: 100%;
    a img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  .text-div {
    text-align: center;
    padding: 0.5rem;

    .post-title {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
    }
    .post-metadata {
      margin: 0.25rem 0 0 0;
      padding: 0;
      font-size: 0.9rem;
    }
  }
}
.post-list-item:nth-of-type(odd) {
  background-color: #f0f0f0;
}
</style>